
import { NavBar, Icon} from 'vant'
export default({
  components:{
    'van-icon': Icon,
    'van-nav-bar': NavBar,
  },
  data() {
    return {
      apis:{//接口列表
        content:'/content/findByCode',//获取购票须知内容
        scenic:'/seller/find',//获取景点信息
      },
      scenicKey:null,//商家ID
      notice:'',//购票须知内容
    }
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  mounted() {
    const me = this;
    me.scenicKey  = me.$route.params.id;
    me.loadScenic();
  },
  methods: {
    loadNotice(){//加载购票须知内容
      const me = this;
      me.$get({
        url: me.apis.content,
        params: {
          code: 'ywh'
        }
      }).then(rsp => {
        me.notice = rsp;
      });
    },
    loadScenic(){//获取景点详情
      const me = this;
      me.scenic = {};
      me.$get({
        url:me.apis.scenic,
        params: {
          sellerId: me.scenicKey
        }
      }).then(rsp => {
        me.notice = rsp ? rsp.remark : '';
      });
    },

  },
})
